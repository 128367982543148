<template>
  <div id="the-preserve" class="mx-auto">
    <MoleculesLoadingOverlay v-if="loading" />
    <Organisms v-else :organisms="organisms" />
  </div>
</template>

<style>
#the-preserve {
  .brand-window-text-overlay {
    @apply mb-0;
    .content {
      @apply items-start;
    }
    h1 {
      @apply drop-shadow-none;

      span {
          @apply font-figtree text-[17px] font-bold uppercase text-white/70 mb-2;
      }
    }
  }

  #icon-columns {
    @apply text-center text-white pt-10 pb-14 px-8;

    img {
      @apply w-14 inline-block pb-4;
    }

    h3 {
      @apply font-figtree text-lg uppercase font-bold;
    }

    .description {
      @apply font-figtree text-lg;
    }
  }

  #preserve-flexible-content {
    @apply mb-0;
    h3 {
      @apply font-figtree text-xl leading-6 pt-4 pb-2;
    }
  }

  #preserve-models {
    @apply py-20 mb-0;
  }

  .split-content {
    h2 {
      @apply font-figtree text-[17px] font-bold uppercase text-fr-secondary mb-4;
    }

    h3 {
      @apply mb-4;
    }
  }

  .swiper {
    @apply pt-20;
  }

  .mb0 {
    @apply mb-0;
  }
}
</style>

<script setup>
//import { useQuery } from "@vue/apollo-composable";
import { useAsyncQuery } from "#imports";
import { GET_ORGANISMS } from "~/graphql/GetOrganisms";
import { storeToRefs } from "pinia";
const breadcrumbs = useBreadcrumbs();
const { breadcrumbsList } = storeToRefs(breadcrumbs);

breadcrumbsList.value = [{ title: "The Preserve", link: "" }];

const { data, pending, error } = await useAsyncQuery(GET_ORGANISMS, { uri: "/the-preserve" });

const organisms = computed(() => {
  return data.value?.organisms?.hlOrganisms?.hlOrganism ?? [];
});

const loading = pending;
</script>
